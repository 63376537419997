@import url(https://fonts.googleapis.com/css2?family=Public+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* FONTS */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiSvgIcon-root {
  vertical-align: middle;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

td {
    vertical-align: top;
}

p {
    margin: 0;
    font-size: large;
  }

.zoom {
    /* padding: 50px;
    background-color: green; */
    transition: transform .5s; /* Animation */
    /* width: 200px;
    height: 200px;
    margin: 0 auto; */
  }

.zoom:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

.onePixBorder {
    border: 1px solid black;
}

.onePixWrapper {
    border-left: 1px solid black;
    border-top: 1px solid black;
}

.onePixChild {
    border-right: 1px solid black;
    border-bottom: 1px solid black;  
}

.onePixRight {
    border-right: 1px solid black;
}

.onePixBottom {
    border-bottom: 1px solid black;
}

.paddingTop {
    padding-top: 85px;
}

.maxwidth {
    max-width: 1000px;
}

.paddingLeft {
    padding-left: 100px;
}

.paddingBottom {
    padding-bottom: 100px;
}

.paddingTopBottom {
    padding-top: 50px;
    padding-bottom: 50px;
}

.marginTop {
    margin-top: 50px;
}

.marginBottom {
    margin-bottom: 70px;
}

.marginTopBottom {
    margin-top: 50px;
    margin-bottom: 50px;
}

hr {
    border: none;
    height: 1px;
    color: #000; 
    background-color: #000; 
}
