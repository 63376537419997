td {
    vertical-align: top;
}

p {
    margin: 0;
    font-size: large;
  }

.zoom {
    /* padding: 50px;
    background-color: green; */
    transition: transform .5s; /* Animation */
    /* width: 200px;
    height: 200px;
    margin: 0 auto; */
  }

.zoom:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

.onePixBorder {
    border: 1px solid black;
}

.onePixWrapper {
    border-left: 1px solid black;
    border-top: 1px solid black;
}

.onePixChild {
    border-right: 1px solid black;
    border-bottom: 1px solid black;  
}

.onePixRight {
    border-right: 1px solid black;
}

.onePixBottom {
    border-bottom: 1px solid black;
}

.paddingTop {
    padding-top: 85px;
}

.maxwidth {
    max-width: 1000px;
}

.paddingLeft {
    padding-left: 100px;
}

.paddingBottom {
    padding-bottom: 100px;
}

.paddingTopBottom {
    padding-top: 50px;
    padding-bottom: 50px;
}

.marginTop {
    margin-top: 50px;
}

.marginBottom {
    margin-bottom: 70px;
}

.marginTopBottom {
    margin-top: 50px;
    margin-bottom: 50px;
}

hr {
    border: none;
    height: 1px;
    color: #000; 
    background-color: #000; 
}